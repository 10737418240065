import React from "react";
import styles from "./support.module.scss";

import { ReactComponent as USDT } from "@assets/svg/coins/USDT.svg";
import { ReactComponent as ETH } from "@assets/svg/coins/ETH.svg";
import { ReactComponent as USDC } from "@assets/svg/coins/USDC.svg";
import { ReactComponent as ISLM } from "@assets/svg/coins/ISLM.svg";
import { ReactComponent as BNB } from "@assets/svg/coins/BNB.svg";
import { ReactComponent as POL } from "@assets/svg/coins/MATIC.svg";
import { ReactComponent as ARB } from "@assets/svg/coins/ARB.svg";
import BASE from "@assets/svg/coins/BASE.png";
import { ReactComponent as FTM } from "@assets/svg/coins/FTM.svg";
import { ReactComponent as MNT } from "@assets/svg/coins/MNT.svg";
import { ReactComponent as AVAX } from "@assets/svg/coins/AVAX.svg";

export const SupportNetworks = () => {
  const icons = [
    { network: ETH, type: "svg" },
    { network: ISLM, type: "svg" },
    { network: BNB, type: "svg" },
    { network: POL, type: "svg" },
    { network: ARB, type: "svg" },
    { network: BASE, type: "png" },
    { network: FTM, type: "svg" },
    { network: AVAX, type: "svg" },
    { network: MNT, type: "svg" },
    { network: USDT, type: "svg" },
    { network: USDC, type: "svg" },
  ];

  return (
    <article className={styles.iconsContainer}>
      <div className={styles["icon-container"]}>
        {icons.map((IconComponent, index) =>
          IconComponent.type === "svg" ? (
            <div key={index} className={styles["icon-wrapper"]}>
              <IconComponent.network />
            </div>
          ) : (
            <div key={index} className={styles["icon-wrapper-png"]}>
              <img src={IconComponent.network} />
            </div>
          )
        )}
      </div>
      <p className={styles.supportText}>Supported EVM networks</p>
    </article>
  );
};
